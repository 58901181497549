<template>
  <div class="blockly-wrapper">
    
    <div class="blockly">
      <div class="logo-wrapper">
        <img src="@/assets/images/white-logo.png" alt="">
      </div>

      <!-- form -->
      <form class="blockly-form">
        <button>Load</button>
        <div class="row no-gutters">
          <div class="col-6">
            <select name="" id="">
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
              <option value="option4">Option 4</option>
              <option value="option5">Option 5</option>
            </select>
          </div>
          <div class="col-6">
            <input type="text" class="form-control">
          </div>
        </div>
        <button>Save</button>
      </form>

      <div class="row mt-4">
        <div class="col-xl-6">
          <div id="blocklyDiv" style="">

            <button @click.prevent="runCode" class="start-now">
              <span>Start Now</span>
            </button>
          </div>

          <textarea
              class="show-code-area d-none"
              id="xml"
              name="xml"
              rows="8"
          ></textarea>

          <xml id="workspaceBlocks" style="display:none">
            <block type="onstart" x="13" y="13"></block>
            <!--<block type="forever" x="188" y="13"> </block>-->
            <!--<block type="if" x="188" y="100"> </block> -->
          </xml>
          <xml id="toolbox" style="display:none">
            <category name="Motor">
              <block type="run"></block>
              <block type="ramp"></block>
              <block type="tankmotors"></block>
              <block type="steermotors"></block>
              <!--<block type="pauseuntil_ready"> </block>
              <block type="pauseuntil_stalled"> </block>-->
              <block type="stop"></block>
              <block type="reset"></block>
              <block type="stopallmotors"></block>
              <block type="resetallmotors"></block>
            </category>
            <category name="Sensor">
              <label text="Touch Sensor" web-class="myLabelStyle"></label>
              <!--<block type="on_touch"> </block>-->
              <block type="pause_until_touch"></block>
              <block type="touch_n_is_pressed"></block>
              <label text="Color Sensor" web-class="myLabelStyle"></label>
              <!--<block type="on_color_sensor_detected"> </block>-->
              <block type="pause_until_color"></block>
              <block type="is_color_sensor_n_detected"></block>
              <block type="color_sensor_n_color"></block>
              <!--<block type="color"> </block>
             <block type="on_color_sensor_light"> </block>
             <block type="pause_until_color_light"> </block>
             <block type="color_sensor_light"> </block>
             <block type="color_sensor_rgb_raw"> </block>-->
              <label text="Ultrasonic Sensor" web-class="myLabelStyle"></label>
              <!--<block type="on_ultrasonic_detected"> </block>-->
              <block type="pause_until_ultrasonic"></block>
              <block type="ultrasonic_distance"></block>
              <label text="Gyro Sensor" web-class="myLabelStyle"></label>
              <block type="gyro_rate"></block>
              <block type="gyro_angle"></block>
              <block type="pause_until_gyro"></block>
              <block type="reset_gyro"></block>
            </category>
            <category name="Loops">
              <block type="onstart"></block>
              <block type="forever"></block>
              <block type="repeat"></block>
              <block type="pause"></block>
            </category>
            <category name="Logic">
              <block type="controls_if"></block>
              <block type="logic_compare">
                <field name="OP">EQ</field>
              </block>
              <block type="logic_operation">
                <field name="OP">AND</field>
              </block>
              <block type="logic_negate"></block>
              <block type="logic_boolean">
                <field name="BOOL">TRUE</field>
              </block>
              <block type="math_number"></block>
            </category>

          </xml>
        </div>
        <div class="col-xl-6">

          <div class="identification">
            <img src="@/assets/images/ID.png" alt="">
          </div>

          <div class="d-flex">
            <div class="video-container">
              <div class="top-left abs-div"></div>
              <div class="left-top abs-div"></div>
              <div class="bottom abs-div"></div>
              <div class="right-bottom abs-div"></div>
              <div class="right-corner abs-div"></div>
              <div class="top-corner abs-div"></div>
              <iframe
                  frameborder="0"
                  id="circle"
                  src="https://admin.wro-v.com/robot2/index.html"
                  scrolling="no"
              ></iframe>
            </div>

            <div class="video-right">
              <img src="@/assets/images/vide-right.png" alt="">
            </div>
          </div>
          <div class="">
            <!--          <img src="./imgs/maximize.svg" class="maximize" alt="icon" />-->
            <!---->
            <!--          <img src="./imgs/minimize.svg" class="minimize d-none" alt="icon" />-->
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="rt-section">
        <p>
          “LEGO®, LEGO® MINDSTORMS® Education EV3 & LEGO® Education are
          trademarks/copyrights owned by the LEGO® Group who does not sponsor,
          authorize or endorse this content and experience.
        </p>
        <p class="font-weight-bold">
          <span class="ml-2"> ملاحظة : </span>
          <span>
            للحصول على تجربة استخدام أفضل ينصح بالدخول من جهاز حاسب شخصي
          </span>
        </p>

      </div>
    </footer>

  </div>
</template>

<script>
import '../../blocks/stocks'
import Blockly from 'blockly';
import $ from 'jquery'

export default {
  name: "Blockly",
  data() {
    return {
      options: {
        media: 'media/',
        toolbox:
            `<xml>
              <category name="Motor">
                  <block type="run"></block>
                  <block type="ramp"></block>
                  <block type="tankmotors"></block>
                  <block type="steermotors"></block>
                  <block type="stop"></block>
                  <block type="reset"></block>
                  <block type="stopallmotors"></block>
                  <block type="resetallmotors"></block>
              </category>
              <category name="Sensor">
                <label text="Touch Sensor" web-class="myLabelStyle"></label>
                <block type="pause_until_touch"></block>
                <block type="touch_n_is_pressed"></block>
                <label text="Color Sensor" web-class="myLabelStyle"></label>
                <block type="pause_until_color"></block>
                <block type="is_color_sensor_n_detected"></block>
                <block type="color_sensor_n_color"></block>
                <label text="Ultrasonic Sensor" web-class="myLabelStyle"></label>
                <block type="pause_until_ultrasonic"></block>
                <block type="ultrasonic_distance"></block>
                <label text="Gyro Sensor" web-class="myLabelStyle"></label>
                <block type="gyro_rate"></block>
                <block type="gyro_angle"></block>
                <block type="pause_until_gyro"></block>
                <block type="reset_gyro"></block>
              </category>
              <category name="Loops" colour="%{BKY_LOOPS_HUE}">
                <block type="onstart"></block>
                <block type="forever"></block>
                <block type="controls_repeat_ext">
                  <value name="TIMES">
                    <block type="math_number">
                      <field name="NUM">5</field>
                    </block>
                  </value>
                </block>
                <block type="pause"></block>
              </category>
              <category name="Logic" colour="%{BKY_LOGIC_HUE}">
                <block type="controls_if"></block>
                <block type="logic_compare"></block>
                <block type="logic_operation"></block>
                <block type="logic_negate"></block>
                <block type="logic_boolean"></block>
                <block type="math_number"></block>
              </category>
            </xml>`
      },
      workspace: null

    }
  },
  mounted() {
    var workspace = Blockly.inject("blocklyDiv", {
      media: "../../media/",
      toolbox: document.getElementById("toolbox"),
    });
    this.workspace = workspace
    Blockly.Xml.domToWorkspace(
        document.getElementById("workspaceBlocks"),
        workspace
    );

    Blockly.JavaScript['run'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['ramp'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['tankmotors'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };


    Blockly.JavaScript['steermotors'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['pauseuntil_ready'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['pauseuntil_stalled'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['stop'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['reset'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };


    Blockly.JavaScript['stopallmotors'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['resetallmotors'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };


    Blockly.JavaScript['on_touch'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['pause_until_touch'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['touch_n_is_pressed'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return [code, Blockly.JavaScript.ORDER_NONE];
    };

    Blockly.JavaScript['on_color_sensor_detected'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['pause_until_color'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['is_color_sensor_n_detected'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return [code, Blockly.JavaScript.ORDER_NONE];
    };

    Blockly.JavaScript['color_sensor_n_color'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return [code, Blockly.JavaScript.ORDER_NONE];
    };

    Blockly.JavaScript['color'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return [code, Blockly.JavaScript.ORDER_NONE];
    };

    Blockly.JavaScript['on_color_sensor_light'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['pause_until_color_light'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['color_sensor_light'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return [code, Blockly.JavaScript.ORDER_NONE];
    };

    Blockly.JavaScript['color_sensor_rgb_raw'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return [code, Blockly.JavaScript.ORDER_NONE];
    };

    Blockly.JavaScript['on_ultrasonic_detected'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['pause_until_ultrasonic'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['ultrasonic_distance'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return [code, Blockly.JavaScript.ORDER_NONE];
    };

    Blockly.JavaScript['gyro_rate'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return [code, Blockly.JavaScript.ORDER_NONE];
    };

    Blockly.JavaScript['gyro_angle'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return [code, Blockly.JavaScript.ORDER_NONE];
    };

    Blockly.JavaScript['pause_until_gyro'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['reset_gyro'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };


    Blockly.JavaScript['onstart'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['forever'] = function () {
      // TODO: Assemble JavaScript into code variable.
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['repeat'] = function () {
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };

    Blockly.JavaScript['pause'] = function () {
      var xml = Blockly.Xml.workspaceToDom(workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      var code = document.getElementById('xml').innerText = xml_text;
      return code;
    };
  },
  methods: {
    runCode() {
      window.LoopTrap = 1000;
      Blockly.JavaScript.INFINITE_LOOP_TRAP =
          'if (--window.LoopTrap == 0) throw "Infinite loop.";\n';
      var code = Blockly.JavaScript.workspaceToCode(this.workspace);
      Blockly.JavaScript.INFINITE_LOOP_TRAP = null;
      try {
        if (Blockly.JavaScript.workspaceToCode(this.workspace) == "") {
          alert()
        }
        eval(code);
      } catch (e) {
        alert()
      }
      var xml = $("#xml").val();
      var foramtedxml = this.formatXml(xml);
      document.getElementById("circle").contentWindow.RunCode(foramtedxml);
    },
    formatXml(xml) {
      var formatted = "<xml>";

      // formatted = s.substring(s.indexOf('\n')+1);

      formatted = formatted.replace(/^>.*/, "");

      var reg = /(>)(<)(\/*)/g;

      formatted = formatted.replace(/(<\s*)(.+:)/, "$1"); // str is now <ActionStatus/>

      xml = xml.replace(reg, "$1\r\n$2$3");

      xml = xml.slice(55);

      var pad = 0;

      $.each(xml.split("\r\n"), function (index, node) {
        var indent = 0;
        if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0;
        } else if (node.match(/^<\/\w/)) {
          if (pad != 0) {
            pad -= 1;
          }
        } else {
          indent = 0;
        }
        // else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        //     indent = 1;
        //   }
        var padding = "";

        for (var i = 0; i < pad; i++) {
          padding += "  ";
        }
        formatted += padding + node + "\r\n";
        pad += indent;
      });

      return formatted;
    }

  }
}
</script>

<style scoped>

</style>